import React from "react"
import {graphql} from 'gatsby'

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import SEO from "../../components/seo"
import { Bar } from 'react-chartjs-2'

const TaxPage = ({data}) => {
    let rates = data.graphAPI.taxRates
    let meta = data.graphAPI.contentTaxRate.meta
    let content = data.graphAPI.contentTaxRate.content
    return (
        <Layout>
            <SEO title={meta.title} />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    <div className="title-section">
                        <h1 className="title is-size-1">{meta.title}</h1>
                    </div>
                    <Content content={content} />
                    <div className="content-align" >
                    <div style={{maxWidth: '600px'}}>
                        <Bar data={{
                            labels: rates.map((rate) => { return (rate.year)}).reverse(),
                            datasets: [
                            {
                                label: "Debt Service",
                                backgroundColor: "rgba(42,188,153,1)",
                                strokeColor: "rgba(42,188,153,0.8)",
                                highlightFill: "rgba(42,188,153,0.75)",
                                highlightStroke: "rgba(42,188,153,1)",
                                data: rates.map((rate) => { return (rate.interestSinkingRate.toFixed(2))}).reverse()
                            },
                            {
                                label: "Maintenance and Operation",
                                backgroundColor: "rgba(42,188,153,0.5)",
                                strokeColor: "rgba(42,188,153,0.8)",
                                highlightFill: "rgba(42,188,153,0.75)",
                                highlightStroke: "rgba(42,188,153,1)",
                                data: rates.map((rate) => { return (rate.maintenanceOperationsRate.toFixed(2))}).reverse()
                            }
                            ]
                        }}
                        options={{
                            scales: {
                            xAxes: [{
                                stacked: true
                            }],
                            yAxes: [{
                                stacked: true
                            }]
                            },
                            tooltips: {
                            mode: 'label',
                            displayColors: false,
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var corporation = data.datasets[tooltipItem.datasetIndex].label;
                                    var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                    var total = 0;
                                    for (var i = 0; i < data.datasets.length; i++)
                                        total += data.datasets[i].data[tooltipItem.index];
                                    if (tooltipItem.datasetIndex != data.datasets.length - 1) {
                                        //   return corporation + " : $" + valor.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                                        return corporation + " : $ " + valor;
                                    } else {
                                        return [corporation + " : $ " + valor];
                                    }
                                }
                            }
                            },
                            scaleBeginAtZero: true
                        }} width="600" height="400" />
                    </div>
                    </div>
                    <div className="content-align">
                    <div className="table-container">
                    <table className="table" style={{marginTop: '3rem'}}>
                        <thead>
                            <tr>
                                <td>Year</td>
                                <td>Debt Service</td>
                                <td>Maintenance</td>
                                <td>Total Tax Rate</td>
                            </tr>
                        </thead>
                        <tbody>
                            {rates.map(rate => (
                                <tr key={rate.id}>
                                    <td>{rate.year}</td>
                                    <td>{(rate.interestSinkingRate).toFixed(2)}</td>
                                    <td>{(rate.maintenanceOperationsRate).toFixed(2)}</td>
                                    <td>{(rate.interestSinkingRate+rate.maintenanceOperationsRate).toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default TaxPage

export const query = graphql`
    query taxPageById {
        graphAPI {
            contentTaxRate {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
            taxRates (sort: "year:desc"){
                year
                maintenanceOperationsRate
                interestSinkingRate
                orderLevyingTaxes {
                    ...publicDocument
                }
            }
        } 
    }
`


